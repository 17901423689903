/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
    @if $point == desktop {
      @media (min-width: 70em) { @content ; }
   }
    @else if $point == laptop {
      @media (min-width: 64em) { @content ; }
   }
     @else if $point == tablet {
      @media (min-width: 50em) { @content ; }
   }
    @else if $point == phablet {
      @media (min-width: 37.5em)  { @content ; }
   }
   @else if $point == mobileonly {
      @media (max-width: 37.5em)  { @content ; }
 
   }
 }
 

// Blues and reds
// $primary_color: #240041;
// $primary-dark_color: darken($primary_color, 2%);
// $text_color: #fff;
// $accent_color: #ff4057;
// $accent-dark_color:  #900048;

// Default Colors
:root {
    --color-primary: #2B0059;
    --color-primaryDark: #230046;
    --color-text: #fff;
    --color-accent: #0ce9c1;
    --color-accentDark: #080010;
    --zindex-background: 10;
    --zindex-navigation: 900;
    --zindex-cursor:1000;
}

// Pink and Grey
// :root {
//     --color-primary: #222831;
//     --color-primaryDark: #230046;
//     --color-text: #fff;
//     --color-accent: #ff4057;
//     --color-accentDark: #15191f;
// }


* {
    margin: 0;
    padding: 0;
    // cursor: none;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

/* to remove the top and left whitespace */
html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/* just to be sure these are full screen*/
canvas {
    display: block;
    position: absolute;
    top:0;
    left: 0;
    z-index: var(--zindex-background);
    
}

.canvasContainer {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 350ms ease-in-out;

    &--loaded {
        opacity: 1;
    }
}

/* To remove the scrollbars */
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

@font-face {
    font-family: 'phosphatesolid';
    src: url('../fonts/phosphate-webfont.eot');
    src: url('../fonts/phosphate-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/phosphate-webfont.woff2') format('woff2'),
        url('../fonts/phosphate-webfont.woff') format('woff'),
        url('../fonts/phosphate-webfont.ttf') format('truetype'),
        url('../fonts/phosphate-webfont.svg#phosphatesolid') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*  MAIN CODE */
body {
    background: var(--color-primary);
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    font-family: 'Open Sans', sans-serif;
}

a {
    color: var(--color-text);
    text-decoration: none;
}

.job {
    &::-webkit-scrollbar {
        background-color: var(--color-primary);
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-accent);
        mix-blend-mode: difference;
    }
    &::-webkit-scrollbar-track {
        width: 2px;
        background-color: rgba(100, 100, 100, 0.14);
        mix-blend-mode: difference;
    }
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: var(--color-primary);
    /* Old browsers */
    background: -moz-linear-gradient(bottom, #ffffff 0%, #ffffff 0%, var(--color-primaryDark) 0%, var(--color-primaryDark) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, #ffffff 0%, #ffffff 0%, var(--color-primaryDark) 0%, var(--color-primaryDark) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, #ffffff 0%, #ffffff 0%, var(--color-primaryDark) 0%, var(--color-primaryDark) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='var(--color-primaryDark)',GradientType=0 ); /* IE6-9 */
    transform: rotate(0deg);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    will-change: transform, top, left;
    transition: transform 450ms ease-in-out, left 450ms ease-in-out, top 450ms ease-in-out;
    line-height: 100%;
    height: 41px;

    @include breakpoint(tablet){
        height: auto;
    }

    &--loaded:after {
        display: none !important;
    }

    &:after {
        display: block;
        content: "L O A D I N G";
        color: red;
        font-weight: 100;
        font-size: 0.1em;
        margin-top: -81px;
        letter-spacing: 8px;
    }
}


.home {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    perspective: 40px;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    opacity: 0;

    &--not-loaded {
        pointer-events: none;
    }

    &--loaded {
        opacity: 1;
    }
}

.home__firstName,
.home__lastName,
.loader {
    display: block;
    text-align: center;
    font-family: 'phosphatesolid', Tahoma, Geneva, Verdana, sans-serif;
    color: #fff;
    font-size: 3em;
    padding: 0;
    margin: 0;
    font-weight: normal;
    perspective: 40px;

    @include breakpoint(tablet){
        font-size: 5em;
    }

    @include breakpoint(laptop){
        font-size: 10em;
    }
}

.home__firstName,
.home__lastName {
    text-shadow: 0px 15px 5px rgba(0, 0, 0, 0.1), 10px 20px 5px rgba(0, 0, 0, 0.05), -10px 20px 5px rgba(0, 0, 0, 0.05);
    line-height: 0.5em;
    @include breakpoint(laptop){
        line-height: 91px;
    }
}

.home__firstName {
    transform: translateZ(10px);
    color: var(--color-accent);
}

.social {
    position: absolute;
    z-index: var(--zindex-navigation);
    top: 10px;
    right: 53px;

    @include breakpoint(tablet) {
        top:auto;
        bottom: 5%;
        right: 5%;
    }

    a {
        padding: 8px;
        font-size: 1.5em;
        &:hover {
            color: var(--color-accent);
        }
    }
}

.resume {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    opacity: 0;
    margin: 110px 16px;
    flex: 1;
    height: calc(100vh - 220px);
    flex-wrap: wrap;

    @include breakpoint(tablet) {
        margin: 110px 67px;
    }


    .info {
        display: flex;
        flex:1;
        flex-flow: column;
        padding-left: 16px;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            padding-left: 0px;
            margin-bottom: 40px;
        }
        
        
        h1 {
            font-size: 5em;
            color: var(--color-accent);
            align-self: flex-start;
            padding: 0;
            text-indent: -5px;
            font-family: 'Raleway', sans-serif;

            @include breakpoint(tablet) {
                font-size: 10em;
                text-indent: -10px;
            }
        }
    }

    .job {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1 1 100%;
        height: calc(100vh);
        overflow-y: overlay;
        overflow-x: visible;
        padding: 8px;
        -webkit-overflow-scrolling: touch;

        @include breakpoint(tablet) {
            height: calc(100vh - 500px);
        }


        &__item {
            flex: 1 1 calc(100% - 134px);
            background: rgba(0, 0, 0, 0.8);
            padding: 16px;
            margin-right: 32px;
            margin-bottom: 32px;
            transition: all 300ms ease-in-out;
            position: relative;

            @include breakpoint(tablet) {
                flex: 1 1 calc(50% - 134px);
            }

            @include breakpoint(laptop) {
                flex: 1 1 calc(34% - 134px);
            }

            // @include breakpoint(desktop) {
            //     flex: 1 1 calc(33.333% - 134px);
            // }


            ul {
                font-size: 1em;
                line-height: 1.5em;
                font-weight: 300;
                padding: 16px;
                list-style: disc;

                li {
                    padding-bottom: 8px;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }

            h2 {
                font-family: 'Raleway', sans-serif;
                margin-bottom: 8px;
            }
    
            h3 {
                margin-bottom: 4px;
               
            }
    
            h4 {
                position: absolute;
                top: 0;
                font-size: 12px;
                font-weight: 800;
                color: var(--color-text);
                background: var(--color-primaryDark);
                padding: 8px 16px;
                right: 30px;
                transform-origin: right top;
                transform: rotate(-90deg);


                @include breakpoint(tablet) {
                    top: 0;
                }
            }

            &:before {
                content: '';
                position: absolute;
                border: rgba(0, 0, 0, 0) solid 4px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: top, right, bottom, left;
                transition-property: top, right, bottom, left;
            }

            &:hover {
                &:before {
                    top: -8px;
                    right: -8px;
                    bottom: -8px;
                    left: -8px;
                    border-color: var(--color-accent);
                }
            }


        }
    }

    &--not-loaded {
        pointer-events: none;
    }

    &--loaded {
        opacity: 1;
    }
}

.cursor {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    border-radius: 50px;
    pointer-events: none;
    z-index: var(--zindex-cursor);
    will-change: transform;

    &__outer {
        position: absolute;
        display: block;
        width: 50px;
        height: 50px;
        top: 0;
        left: 0;
        border: 1px solid rgba(0, 0, 0, 0);
        border-radius: 50px;
        pointer-events: none;
    }

    &__inner {
        pointer-events: none;
        display: block;
        position: relative;
        width: 10px;
        height: 10px;
        background: #fff;
        top: 20px;
        left: 20px;
        border-radius: 20px;
    }
}

.navigation {
    position: absolute;
    // top: 20px;
    // right: 27px;
    top: 12px;
    right: 8px;
    z-index: var(--zindex-navigation);
    transform: scale(0.7);

    @include breakpoint(tablet){
        top: 40px;
        right: 67px;
        transform: scale(1);
    }

    &__top,
    &__mid,
    &__bottom {
        display: block;
        background: #ffffff;
        height: 4px;
        width: 40px;
        margin-bottom: 8px;
        opacity: 1;
        transform-origin: center left;
        transition: all 0.3s ease-in-out;
    }

    &__mid {
        width: 35px;
    }

    &__bottom {
        width: 30px;
    }

    &:hover {
        .navigation__top {
            transform: scaleX(0.5);
        }

        .navigation__mid {
            transform: scaleX(1.1);
        }

        .navigation__bottom {
            width: 40px;
            transform: scaleX(0.5);
        }
    }

    &.open {
        .navigation__top {
            transform: rotate(45deg);
        }

        .navigation__mid {
            opacity: 0;
            transform: translateX(20px);
        }

        .navigation__bottom {
            width: auto;
            transform: translateY(5px) rotate(-45deg);
        }
    }

    &-modal {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: var(--color-accentDark);
        transition: all 300ms ease-in-out;
        transform: translateX(0%);
    
        &.hide {
            transform: translateX(-100%);
        }
    
        & nav {
            display: flex;
            height: 100vh;
            width: 100vw;
    
            ul {
                display: flex;
                width: 100vw;
                flex-direction: column;
                justify-content: center;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-self: center;
                align-items: center;
    
                li {
                    flex: 1;
    
                    a {
                        position: relative;
                        text-transform: uppercase;
                        font-family: 'Raleway', sans-serif;
                        font-weight: 800;
                        font-size: 2em;
                        line-height: 3em;
                        
                        border-bottom: 30px solid rgba(0, 0, 0, 0);

                        @include breakpoint(tablet){
                            font-size: 8em;
                            line-height: 2em;
                        }
                        
    
                        &.active {
                            color: var(--color-accent);
    
                            &:before {
                                border-left-color: var(--color-text);
                            }
    
                            &:after {
                                border-bottom-color: var(--color-text);
                            }
                        }
    
                        &:hover:after {
                            width: 115%;
                            @include breakpoint(tablet){
                                width: 110%;
                            }
                        }
    
                        &:hover:before {
                            right: -20px;

                            @include breakpoint(tablet){
                                right: -30px;
                            }
                        }
    
                        &:before {
                            display: block;
                            position: absolute;
                            content: "";
                            top: 3px;
                            right: 105%;
                            width: 0;
                            height: 0;
                            border-top: 15px solid transparent;
                            border-bottom: 15px solid transparent;
                            border-left: 15px solid var(--color-accent);
                            transition: all 300ms ease-in-out;

                            @include breakpoint(tablet){
                                top: 45px;
                                border-top: 30px solid transparent;
                                border-bottom: 30px solid transparent;
                                border-left: 30px solid var(--color-accent);
                            }
                        }
    
                        &:after {
                            display: block;
                            content: "";
                            position: absolute;
                            top: 14px;
                            left: -10%;
                            width: 0;
                            transition: all 300ms ease-in-out;
                            border-bottom: 7px solid var(--color-accent);

                            @include breakpoint(tablet){
                                top: 60px;
                                border-bottom: 30px solid var(--color-accent);
                            }
                        }
                    }
                }
            }
        }
    }
}

.resume-title {

    &--loaded .char {
        animation: an 1s ease-out 2s both;
    }
    .char {
        display: inline-block;
    }
}



@keyframes an {
    from{
        opacity: 0;
        transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    to{
        opacity: 1;
        transform: perspective(500px) translate3d(0, 0, 0);
    }
}