:root {
  --color-primary: #2b0059;
  --color-primaryDark: #230046;
  --color-text: #fff;
  --color-accent: #0ce9c1;
  --color-accentDark: #080010;
  --zindex-background: 10;
  --zindex-navigation: 900;
  --zindex-cursor: 1000;
}

* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

canvas {
  z-index: var(--zindex-background);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.canvasContainer {
  opacity: 0;
  transition: opacity .35s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.canvasContainer--loaded {
  opacity: 1;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@font-face {
  font-family: phosphatesolid;
  src: url("phosphate-webfont.57856612.eot");
  src: url("phosphate-webfont.57856612.eot#iefix") format("embedded-opentype"), url("phosphate-webfont.17a4a43d.woff2") format("woff2"), url("phosphate-webfont.393ca2d1.woff") format("woff"), url("phosphate-webfont.f0f17a91.ttf") format("truetype"), url("phosphate-webfont.5997013b.svg#phosphatesolid") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  background: var(--color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  font-family: Open Sans, sans-serif;
  position: relative;
}

a {
  color: var(--color-text);
  text-decoration: none;
}

.job::-webkit-scrollbar {
  background-color: var(--color-primary);
  width: 2px;
}

.job::-webkit-scrollbar-thumb {
  background-color: var(--color-accent);
  mix-blend-mode: difference;
}

.job::-webkit-scrollbar-track {
  width: 2px;
  mix-blend-mode: difference;
  background-color: #64646424;
}

.loader {
  background-color: var(--color-primary);
  background: -moz-linear-gradient(bottom, #fff 0%, #fff 0%, var(--color-primaryDark) 0%, var(--color-primaryDark) 100%);
  background: -webkit-linear-gradient(bottom, #fff 0%, #fff 0%, var(--color-primaryDark) 0%, var(--color-primaryDark) 100%);
  background: linear-gradient(to top, #fff 0%, #fff 0%, var(--color-primaryDark) 0%, var(--color-primaryDark) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  will-change: transform, top, left;
  height: 41px;
  line-height: 100%;
  transition: transform .45s ease-in-out, left .45s ease-in-out, top .45s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(0deg);
}

@media (min-width: 50em) {
  .loader {
    height: auto;
  }
}

.loader--loaded:after {
  display: none !important;
}

.loader:after {
  content: "L O A D I N G";
  color: red;
  letter-spacing: 8px;
  margin-top: -81px;
  font-size: .1em;
  font-weight: 100;
  display: block;
}

.home {
  perspective: 40px;
  transform-style: preserve-3d;
  opacity: 0;
  margin-right: -50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home--not-loaded {
  pointer-events: none;
}

.home--loaded {
  opacity: 1;
}

.home__firstName, .home__lastName, .loader {
  text-align: center;
  color: #fff;
  perspective: 40px;
  margin: 0;
  padding: 0;
  font-family: phosphatesolid, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 3em;
  font-weight: normal;
  display: block;
}

@media (min-width: 50em) {
  .home__firstName, .home__lastName, .loader {
    font-size: 5em;
  }
}

@media (min-width: 64em) {
  .home__firstName, .home__lastName, .loader {
    font-size: 10em;
  }
}

.home__firstName, .home__lastName {
  text-shadow: 0 15px 5px #0000001a, 10px 20px 5px #0000000d, -10px 20px 5px #0000000d;
  line-height: .5em;
}

@media (min-width: 64em) {
  .home__firstName, .home__lastName {
    line-height: 91px;
  }
}

.home__firstName {
  color: var(--color-accent);
  transform: translateZ(10px);
}

.social {
  z-index: var(--zindex-navigation);
  position: absolute;
  top: 10px;
  right: 53px;
}

@media (min-width: 50em) {
  .social {
    top: auto;
    bottom: 5%;
    right: 5%;
  }
}

.social a {
  padding: 8px;
  font-size: 1.5em;
}

.social a:hover {
  color: var(--color-accent);
}

.resume {
  opacity: 0;
  height: calc(100vh - 220px);
  flex-wrap: wrap;
  flex: 1;
  margin: 110px 16px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

@media (min-width: 50em) {
  .resume {
    margin: 110px 67px;
  }
}

.resume .info {
  flex-flow: column;
  flex: 1;
  margin-bottom: 20px;
  padding-left: 16px;
  display: flex;
}

@media (min-width: 50em) {
  .resume .info {
    margin-bottom: 40px;
    padding-left: 0;
  }
}

.resume .info h1 {
  color: var(--color-accent);
  text-indent: -5px;
  align-self: flex-start;
  padding: 0;
  font-family: Raleway, sans-serif;
  font-size: 5em;
}

@media (min-width: 50em) {
  .resume .info h1 {
    text-indent: -10px;
    font-size: 10em;
  }
}

.resume .job {
  height: 100vh;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
  flex-flow: wrap;
  flex: 100%;
  padding: 8px;
  display: flex;
  overflow-x: visible;
}

@media (min-width: 50em) {
  .resume .job {
    height: calc(100vh - 500px);
  }
}

.resume .job__item {
  background: #000c;
  flex: calc(100% - 134px);
  margin-bottom: 32px;
  margin-right: 32px;
  padding: 16px;
  transition: all .3s ease-in-out;
  position: relative;
}

@media (min-width: 50em) {
  .resume .job__item {
    flex: calc(50% - 134px);
  }
}

@media (min-width: 64em) {
  .resume .job__item {
    flex: calc(34% - 134px);
  }
}

.resume .job__item ul {
  padding: 16px;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5em;
  list-style: disc;
}

.resume .job__item ul li {
  padding-bottom: 8px;
}

.resume .job__item ul li:last-child {
  padding-bottom: 0;
}

.resume .job__item h2 {
  margin-bottom: 8px;
  font-family: Raleway, sans-serif;
}

.resume .job__item h3 {
  margin-bottom: 4px;
}

.resume .job__item h4 {
  color: var(--color-text);
  background: var(--color-primaryDark);
  transform-origin: 100% 0;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 800;
  position: absolute;
  top: 0;
  right: 30px;
  transform: rotate(-90deg);
}

@media (min-width: 50em) {
  .resume .job__item h4 {
    top: 0;
  }
}

.resume .job__item:before {
  content: "";
  border: 4px solid #0000;
  transition-property: top, right, bottom, left;
  transition-duration: .3s;
  position: absolute;
  inset: 0;
}

.resume .job__item:hover:before {
  border-color: var(--color-accent);
  inset: -8px;
}

.resume--not-loaded {
  pointer-events: none;
}

.resume--loaded {
  opacity: 1;
}

.cursor {
  width: 50px;
  height: 50px;
  pointer-events: none;
  z-index: var(--zindex-cursor);
  will-change: transform;
  border-radius: 50px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.cursor__outer {
  width: 50px;
  height: 50px;
  pointer-events: none;
  border: 1px solid #0000;
  border-radius: 50px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.cursor__inner {
  pointer-events: none;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 20px;
  display: block;
  position: relative;
  top: 20px;
  left: 20px;
}

.navigation {
  z-index: var(--zindex-navigation);
  position: absolute;
  top: 12px;
  right: 8px;
  transform: scale(.7);
}

@media (min-width: 50em) {
  .navigation {
    top: 40px;
    right: 67px;
    transform: scale(1);
  }
}

.navigation__top, .navigation__mid, .navigation__bottom {
  height: 4px;
  width: 40px;
  opacity: 1;
  transform-origin: 0;
  background: #fff;
  margin-bottom: 8px;
  transition: all .3s ease-in-out;
  display: block;
}

.navigation__mid {
  width: 35px;
}

.navigation__bottom {
  width: 30px;
}

.navigation:hover .navigation__top {
  transform: scaleX(.5);
}

.navigation:hover .navigation__mid {
  transform: scaleX(1.1);
}

.navigation:hover .navigation__bottom {
  width: 40px;
  transform: scaleX(.5);
}

.navigation.open .navigation__top {
  transform: rotate(45deg);
}

.navigation.open .navigation__mid {
  opacity: 0;
  transform: translateX(20px);
}

.navigation.open .navigation__bottom {
  width: auto;
  transform: translateY(5px)rotate(-45deg);
}

.navigation-modal {
  width: 100%;
  height: 100%;
  background: var(--color-accentDark);
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
}

.navigation-modal.hide {
  transform: translateX(-100%);
}

.navigation-modal nav {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.navigation-modal nav ul {
  width: 100vw;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
  display: flex;
}

.navigation-modal nav ul li {
  flex: 1;
}

.navigation-modal nav ul li a {
  text-transform: uppercase;
  border-bottom: 30px solid #0000;
  font-family: Raleway, sans-serif;
  font-size: 2em;
  font-weight: 800;
  line-height: 3em;
  position: relative;
}

@media (min-width: 50em) {
  .navigation-modal nav ul li a {
    font-size: 8em;
    line-height: 2em;
  }
}

.navigation-modal nav ul li a.active {
  color: var(--color-accent);
}

.navigation-modal nav ul li a.active:before {
  border-left-color: var(--color-text);
}

.navigation-modal nav ul li a.active:after {
  border-bottom-color: var(--color-text);
}

.navigation-modal nav ul li a:hover:after {
  width: 115%;
}

@media (min-width: 50em) {
  .navigation-modal nav ul li a:hover:after {
    width: 110%;
  }
}

.navigation-modal nav ul li a:hover:before {
  right: -20px;
}

@media (min-width: 50em) {
  .navigation-modal nav ul li a:hover:before {
    right: -30px;
  }
}

.navigation-modal nav ul li a:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid #0000;
  border-bottom: 15px solid #0000;
  border-left: 15px solid var(--color-accent);
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
  top: 3px;
  right: 105%;
}

@media (min-width: 50em) {
  .navigation-modal nav ul li a:before {
    border-top: 30px solid #0000;
    border-bottom: 30px solid #0000;
    border-left: 30px solid var(--color-accent);
    top: 45px;
  }
}

.navigation-modal nav ul li a:after {
  content: "";
  width: 0;
  border-bottom: 7px solid var(--color-accent);
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
  top: 14px;
  left: -10%;
}

@media (min-width: 50em) {
  .navigation-modal nav ul li a:after {
    border-bottom: 30px solid var(--color-accent);
    top: 60px;
  }
}

.resume-title--loaded .char {
  animation: an 1s ease-out 2s both;
}

.resume-title .char {
  display: inline-block;
}

@keyframes an {
  from {
    opacity: 0;
    transform: perspective(500px)translate3d(-35px, -40px, -150px)rotate3d(1, -1, 0, 35deg);
  }

  to {
    opacity: 1;
    transform: perspective(500px)translate3d(0, 0, 0);
  }
}

/*# sourceMappingURL=index.caa2f9bf.css.map */
